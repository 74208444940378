#form_quote {
  /*!
  * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
  * License - https://fontawesome.com/license (Commercial License)
  */
}
#form_quote #msform {
  margin: 0px auto;
  text-align: center;
  position: relative;
}
#form_quote #msform fieldset {
  font-size: 14px;
  background: #fff;
  border: 0 none;
  border-radius: 3px;
  padding: 0px 30px 20px;
  box-sizing: border-box;
  width: 90%;
  margin: auto;
  height: 460px;
  position: relative;
}
#form_quote #big_name {
  display: block;
}
#form_quote #msform fieldset:not(:first-of-type) {
  display: none;
}
@media screen and (min-width: 426px) {
  #form_quote #msform {
    width: 450px;
  }
  #form_quote #msform fieldset {
    width: 80%;
    margin: 0 10%;
  }
}
#form_quote #msform input,
#form_quote #msform textarea {
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
}
#form_quote #msform button.aviva {
  color: #FFF;
  background-color: #00B5F7;
  transition: background-color 0.2s linear;
}
#form_quote #msform button.aviva:hover {
  background-color: #009CDE;
}
#form_quote #msform .widget-header {
  display: flex;
  align-items: center;
}
#form_quote #msform .input-group {
  grid-template-columns: 100%;
}
#form_quote #msform .input-group .select-wrapper select {
  width: 100%;
}
#form_quote #msform .widget-header .widget-steps {
  text-align: right;
  line-height: normal;
  margin-left: auto;
}
#form_quote #msform .widget-header .widget-steps .minor {
  color: #C5C5C5;
  display: block;
  font-size: 12px;
}
#form_quote #msform .widget-header .widget-steps .major {
  font-size: 18px;
}
#form_quote #msform {
  margin-bottom: -1px;
}
#form_quote #msform fieldset > div {
  max-height: 350px;
}
#form_quote section.assure_quote div.section.split {
  padding-bottom: 0;
  overflow: hidden;
}
#form_quote #msform fieldset {
  box-shadow: none;
  min-height: 555px;
}
#form_quote button.next.aviva {
  margin-left: auto;
  margin-right: auto;
  width: 240px;
  max-width: 240px;
}
#form_quote .dollar-value::before {
  content: "$";
  position: absolute;
  color: #fff;
  z-index: 1;
  background-color: #00B5F7;
  border-radius: 2px 0 0 2px;
  border: 1px solid #1b1b1b;
  border-right: none;
  height: 100%;
  width: 38px;
  font-size: 19px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
#form_quote .dollar-value input {
  text-indent: 39px !important;
}
#form_quote .dollar-value label {
  padding-left: 32px !important;
}
#form_quote input[type=range] {
  -webkit-appearance: none;
}
#form_quote input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}
#form_quote input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-top: -5px;
}
#form_quote input[type=range]:focus {
  outline: none;
}
#form_quote input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
#form_quote #msform input[type=range]::-webkit-slider-thumb {
  background-color: #00B5F7;
}
#form_quote #msform .action-button {
  width: 100px;
  background: #27AE60;
  font-weight: 700;
  color: #fff;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}
#form_quote #msform .action-button:hover,
#form_quote #msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #27AE60;
}
#form_quote .fs-title {
  font-size: 15px;
  text-transform: uppercase;
  color: #2C3E50;
  margin-bottom: 10px;
}
#form_quote .fs-subtitle {
  font-weight: 400;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}
#form_quote #progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
}
#form_quote #progressbar li {
  list-style-type: none;
  color: #1b1b1b;
  text-transform: capitalize;
  font-size: 10px;
  margin: 0 4px;
  float: left;
  position: relative;
}
#form_quote #progressbar li:before {
  content: "";
  width: 96px;
  height: 16px;
  display: block;
  font-size: 10px;
  color: #333;
  background: #fff;
  border: 1px solid #243746;
  border-radius: 2px;
  margin: 0 auto 5px auto;
}
#form_quote #progressbar li:after {
  width: 100%;
  height: 2px;
  background: #fff;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1;
}
#form_quote #progressbar li:first-child:after {
  content: none;
}
#form_quote #progressbar li.active:before,
#form_quote #progressbar li.active:after {
  background: #243746;
  color: #fff;
}
#form_quote #msform input.text-alt {
  border: none;
  border-bottom: 2px solid #ababab;
  border-radius: 0;
  text-align: center;
  font-size: 25px;
  text-indent: 0;
}
#form_quote #msform input.text-alt::placeholder {
  color: #ababab;
}
#form_quote .pulse {
  animation-name: pulse_anim;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes pulse_anim {
  from {
    transform: scale(0.9);
    opacity: 0.6;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
#form_quote html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
#form_quote body {
  margin: 0;
}
#form_quote main {
  display: block;
}
#form_quote h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
#form_quote hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
#form_quote pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
#form_quote a {
  background-color: transparent;
}
#form_quote abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
#form_quote b,
#form_quote strong {
  font-weight: bolder;
}
#form_quote code,
#form_quote kbd,
#form_quote samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
#form_quote small {
  font-size: 80%;
}
#form_quote sub,
#form_quote sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
#form_quote sub {
  bottom: -0.25em;
}
#form_quote sup {
  top: -0.5em;
}
#form_quote img {
  border-style: none;
}
#form_quote button,
#form_quote input,
#form_quote optgroup,
#form_quote select,
#form_quote textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
#form_quote button,
#form_quote input {
  overflow: visible;
}
#form_quote button,
#form_quote select {
  text-transform: none;
}
#form_quote button,
#form_quote [type=button],
#form_quote [type=reset],
#form_quote [type=submit] {
  -webkit-appearance: button;
}
#form_quote button::-moz-focus-inner,
#form_quote [type=button]::-moz-focus-inner,
#form_quote [type=reset]::-moz-focus-inner,
#form_quote [type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
#form_quote button:-moz-focusring,
#form_quote [type=button]:-moz-focusring,
#form_quote [type=reset]:-moz-focusring,
#form_quote [type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
#form_quote fieldset {
  padding: 0.35em 0.75em 0.625em;
}
#form_quote legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
#form_quote progress {
  vertical-align: baseline;
}
#form_quote textarea {
  overflow: auto;
}
#form_quote [type=checkbox],
#form_quote [type=radio] {
  box-sizing: border-box;
  padding: 0;
}
#form_quote [type=number]::-webkit-inner-spin-button,
#form_quote [type=number]::-webkit-outer-spin-button {
  height: auto;
}
#form_quote [type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
#form_quote [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
#form_quote ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
#form_quote details {
  display: block;
}
#form_quote summary {
  display: list-item;
}
#form_quote template {
  display: none;
}
#form_quote [hidden] {
  display: none;
}
#form_quote *,
#form_quote *::before,
#form_quote *::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#form_quote body,
#form_quote h1,
#form_quote h2,
#form_quote h3,
#form_quote h4,
#form_quote p,
#form_quote ul[class],
#form_quote ol[class],
#form_quote li,
#form_quote figure,
#form_quote figcaption,
#form_quote blockquote,
#form_quote dl,
#form_quote dd {
  margin: 0;
}
#form_quote body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
#form_quote ul[class],
#form_quote ol[class] {
  list-style: none;
}
#form_quote a:not([class]) {
  text-decoration-skip-ink: auto;
}
#form_quote img {
  max-width: 100%;
  display: block;
}
#form_quote article > * + * {
  margin-top: 1em;
}
#form_quote input,
#form_quote button,
#form_quote textarea,
#form_quote select {
  font: inherit;
}
#form_quote div {
  display: block;
}
@media (prefers-reduced-motion: reduce) {
  #form_quote * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-family: "DIN Next Slab Pro Bold";
  src: url("boatsmart-fonts/DIN_Next_Slab_Pro_Bold.eot?#iefix");
  src: url("boatsmart-fonts/DIN_Next_Slab_Pro_Bold.eot?#iefix") format("eot"), url("boatsmart-fonts/DIN_Next_Slab_Pro_Bold.ttf") format("truetype"), url("boatsmart-fonts/DIN_Next_Slab_Pro_Bold.svg") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "DIN Next LT Pro";
  font-weight: 700;
  src: url("boatsmart-fonts/324dee78-6585-4c13-8ca3-5817cc600d84.eot?#iefix");
  src: url("boatsmart-fonts/324dee78-6585-4c13-8ca3-5817cc600d84.eot?#iefix") format("eot"), url("boatsmart-fonts/be607c9f-3c81-41b4-b7ad-17af005c80bb.woff2") format("woff2"), url("boatsmart-fonts/4bf0ead4-e61b-4992-832b-6ff05828d99f.woff") format("woff"), url("boatsmart-fonts/73713a0b-8f6a-4c4e-b8a8-7cf238e632f6.ttf") format("truetype"), url("boatsmart-fonts/b90b6bdf-15e2-4544-9fe0-92c79fff0ecd.svg#b90b6bdf-15e2-4544-9fe0-92c79fff0ecd") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "DIN Next LT Pro";
  font-weight: 900;
  src: url("boatsmart-fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix");
  src: url("boatsmart-fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix") format("eot"), url("boatsmart-fonts/03008527-67bd-478e-98e3-3b56dd9a6520.woff2") format("woff2"), url("boatsmart-fonts/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff") format("woff"), url("boatsmart-fonts/92ed1479-1d40-4a94-8baf-6abd88b17afa.ttf") format("truetype"), url("boatsmart-fonts/36ae914b-b535-47cd-b3d2-8c43073d8634.svg#36ae914b-b535-47cd-b3d2-8c43073d8634") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "DIN Next LT Pro";
  font-weight: 200;
  src: url("boatsmart-fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix");
  src: url("boatsmart-fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix") format("eot"), url("boatsmart-fonts/de1216be-00c2-43c0-9c45-3e7f925eb519.woff2") format("woff2"), url("boatsmart-fonts/bc176270-17fa-4c78-a343-9fe52824e501.woff") format("woff"), url("boatsmart-fonts/3516f91d-ac48-42cd-acfe-1be691152cc4.ttf") format("truetype"), url("boatsmart-fonts/d1b1e866-a411-42ba-8f75-72bf28e23694.svg#d1b1e866-a411-42ba-8f75-72bf28e23694") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "DIN Next LT Pro";
  src: url("boatsmart-fonts/da088ecc-a53c-4831-a95b-6fd81b5b338a.eot?#iefix");
  src: url("boatsmart-fonts/da088ecc-a53c-4831-a95b-6fd81b5b338a.eot?#iefix") format("eot"), url("boatsmart-fonts/44e7b0fa-6c8d-43c2-b19e-f1e3ce9ea57c.woff2") format("woff2"), url("boatsmart-fonts/c5a7f89e-15b6-49a9-8259-5ea665e72191.woff") format("woff"), url("boatsmart-fonts/868c94b5-ca49-46b7-b62b-297f8d861125.ttf") format("truetype"), url("boatsmart-fonts/e3145f65-29b5-4f7f-8c6e-2dc135021b6a.svg#e3145f65-29b5-4f7f-8c6e-2dc135021b6a") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwaPGQ3q5d0N7w.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}
#form_quote .cc-fire:not(.stripe):not(.button):not(button) {
  color: #d2451e !important;
}
#form_quote .cc-octane:not(.stripe):not(.button):not(button) {
  color: #BA0020 !important;
}
#form_quote .cc-sun:not(.stripe):not(.button):not(button) {
  color: #f09521 !important;
}
#form_quote .cc-water:not(.stripe):not(.button):not(button) {
  color: #243746 !important;
}
#form_quote .cc-twilight:not(.stripe):not(.button):not(button) {
  color: #1F495C !important;
}
#form_quote .cc-dew:not(.stripe):not(.button):not(button) {
  color: #3E728C !important;
}
#form_quote .cc-bs-green:not(.stripe):not(.button):not(button) {
  color: #8CBA59 !important;
}
#form_quote .cc-forest:not(.stripe):not(.button):not(button) {
  color: #2c5424 !important;
}
#form_quote .cc-moss:not(.stripe):not(.button):not(button) {
  color: #70794C !important;
}
#form_quote .cc-dirt:not(.stripe):not(.button):not(button) {
  color: #483829 !important;
}
#form_quote .cc-desert:not(.stripe):not(.button):not(button) {
  color: #c6b682 !important;
}
#form_quote .cc-clay:not(.stripe):not(.button):not(button) {
  color: #d6d1ca !important;
}
#form_quote .cc-midnight:not(.stripe):not(.button):not(button) {
  color: #1b1b1b !important;
}
#form_quote .cc-fog:not(.stripe):not(.button):not(button) {
  color: #a7a9ac !important;
}
#form_quote .cc-mist:not(.stripe):not(.button):not(button) {
  color: #f6f6f6 !important;
}
#form_quote .cc-white:not(.stripe):not(.button):not(button) {
  color: #fff !important;
}
#form_quote a.button:disabled,
#form_quote button:disabled {
  background-color: #a7a9ac !important;
}
#form_quote .stripe.multiply::after {
  mix-blend-mode: multiply;
}
#form_quote .stripe {
  position: relative !important;
}
#form_quote .stripe > * {
  position: relative;
  z-index: 1;
}
#form_quote .stripe::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(100% 0, 100% 30%, 0 100%, 0 70%);
  clip-path: polygon(100% 0, 100% 30%, 0 100%, 0 70%);
  top: 0;
  left: 0;
}
#form_quote .stripe.cc-fog::after {
  background-color: #a7a9ac;
  opacity: 0.5;
}
#form_quote .stripe.cc-mist::after {
  background-color: #f6f6f6;
  opacity: 0.5;
}
#form_quote .stripe.cc-mist-m::after {
  background-color: #f6f6f6;
  opacity: 1;
}
#form_quote .stripe.cc-midnight::after {
  background-color: #1b1b1b;
  opacity: 1;
}
#form_quote .stripe.cc-midnight::after {
  background-color: #1b1b1b;
  opacity: 1;
}
#form_quote .stripe.cc-white::after {
  background-color: #fff;
  opacity: 1;
}
#form_quote .stripe.bs-hero::after {
  background-color: #3E5263;
  opacity: 0.05;
}
#form_quote .stripe.stripe-octane::after {
  background-color: #BA0020;
  opacity: 1;
}
#form_quote .stripe.stripe-moss::after {
  background-color: #70794C;
  opacity: 0.75;
}
#form_quote .stripe.stripe-fire::after {
  background-color: #d2451e;
  opacity: 1;
}
#form_quote .stripe.stripe-moss::after {
  z-index: 1;
  mix-blend-mode: darken;
}
#form_quote h1,
#form_quote h2,
#form_quote h3,
#form_quote h4,
#form_quote h5 {
  font-family: "DIN Next Slab Pro Bold", Helvetica, Arial, sans-serif;
  font-weight: inherit;
  margin: unset;
  text-transform: uppercase;
}
#form_quote p + h5,
#form_quote p + .h5,
#form_quote p + .subscript,
#form_quote div + h5,
#form_quote div + .h5,
#form_quote div + .subscript,
#form_quote ul + h5,
#form_quote ul + .h5,
#form_quote ul + .subscript {
  margin-top: 4px;
}
#form_quote p + h4,
#form_quote p + .h4,
#form_quote p + .exsmall,
#form_quote div + h4,
#form_quote div + .h4,
#form_quote div + .exsmall,
#form_quote ul + h4,
#form_quote ul + .h4,
#form_quote ul + .exsmall {
  margin-top: 8px;
}
#form_quote p + h3,
#form_quote p + .h3,
#form_quote p + .small,
#form_quote div + h3,
#form_quote div + .h3,
#form_quote div + .small,
#form_quote ul + h3,
#form_quote ul + .h3,
#form_quote ul + .small {
  margin-top: 8px;
}
#form_quote p + h2,
#form_quote p + .h2,
#form_quote p + .med,
#form_quote div + h2,
#form_quote div + .h2,
#form_quote div + .med,
#form_quote ul + h2,
#form_quote ul + .h2,
#form_quote ul + .med {
  margin-top: 16px;
}
#form_quote p + h1,
#form_quote p + .h1,
#form_quote p + .lrg,
#form_quote div + h1,
#form_quote div + .h1,
#form_quote div + .lrg,
#form_quote ul + h1,
#form_quote ul + .h1,
#form_quote ul + .lrg {
  margin-top: 16px;
}
#form_quote p:not(.p-sub) + h4,
#form_quote p:not(.p-sub) + h3,
#form_quote ul + h4,
#form_quote ul + h3,
#form_quote ol + h4,
#form_quote ol + h3 {
  margin-top: 80px;
}
#form_quote img + h5,
#form_quote img + .h5,
#form_quote img + .subscript,
#form_quote picture + h5,
#form_quote picture + .h5,
#form_quote picture + .subscript,
#form_quote div + h5,
#form_quote div + .h5,
#form_quote div + .subscript,
#form_quote picture + noscript + h5,
#form_quote picture + noscript + .h5,
#form_quote picture + noscript + .subscript,
#form_quote img + noscript + h5,
#form_quote img + noscript + .h5,
#form_quote img + noscript + .subscript {
  margin-top: 24px;
}
#form_quote img + h4,
#form_quote img + .h4,
#form_quote img + .exsmall,
#form_quote picture + h4,
#form_quote picture + .h4,
#form_quote picture + .exsmall,
#form_quote div + h4,
#form_quote div + .h4,
#form_quote div + .exsmall,
#form_quote picture + noscript + h4,
#form_quote picture + noscript + .h4,
#form_quote picture + noscript + .exsmall,
#form_quote img + noscript + h4,
#form_quote img + noscript + .h4,
#form_quote img + noscript + .exsmall {
  margin-top: 24px;
}
#form_quote img + h3,
#form_quote img + .h3,
#form_quote img + .small,
#form_quote picture + h3,
#form_quote picture + .h3,
#form_quote picture + .small,
#form_quote div + h3,
#form_quote div + .h3,
#form_quote div + .small,
#form_quote picture + noscript + h3,
#form_quote picture + noscript + .h3,
#form_quote picture + noscript + .small,
#form_quote img + noscript + h3,
#form_quote img + noscript + .h3,
#form_quote img + noscript + .small {
  margin-top: 24px;
}
#form_quote img + h2,
#form_quote img + .h2,
#form_quote img + .med,
#form_quote picture + h2,
#form_quote picture + .h2,
#form_quote picture + .med,
#form_quote div + h2,
#form_quote div + .h2,
#form_quote div + .med,
#form_quote picture + noscript + h2,
#form_quote picture + noscript + .h2,
#form_quote picture + noscript + .med,
#form_quote img + noscript + h2,
#form_quote img + noscript + .h2,
#form_quote img + noscript + .med {
  margin-top: 24px;
}
#form_quote img + h1,
#form_quote img + .h1,
#form_quote img + .lrg,
#form_quote picture + h1,
#form_quote picture + .h1,
#form_quote picture + .lrg,
#form_quote div + h1,
#form_quote div + .h1,
#form_quote div + .lrg,
#form_quote picture + noscript + h1,
#form_quote picture + noscript + .h1,
#form_quote picture + noscript + .lrg,
#form_quote img + noscript + h1,
#form_quote img + noscript + .h1,
#form_quote img + noscript + .lrg {
  margin-top: 24px;
}
#form_quote h5,
#form_quote .h5,
#form_quote .subscript {
  font-size: 0.85em;
  line-height: 0.75em;
}
@media screen and (min-width: 768px) {
  #form_quote h5,
  #form_quote .h5,
  #form_quote .subscript {
    font-size: 1em;
    line-height: 1em;
  }
}
#form_quote h5 + p,
#form_quote h5 + p.psub,
#form_quote h5 + p + p,
#form_quote h5 + p + p:empty + *,
#form_quote h5 + div,
#form_quote h5 + p:empty + *,
#form_quote h5 + ul,
#form_quote h5 + ol,
#form_quote .h5 + p,
#form_quote .h5 + p.psub,
#form_quote .h5 + p + p,
#form_quote .h5 + p + p:empty + *,
#form_quote .h5 + div,
#form_quote .h5 + p:empty + *,
#form_quote .h5 + ul,
#form_quote .h5 + ol,
#form_quote .subscript + p,
#form_quote .subscript + p.psub,
#form_quote .subscript + p + p,
#form_quote .subscript + p + p:empty + *,
#form_quote .subscript + div,
#form_quote .subscript + p:empty + *,
#form_quote .subscript + ul,
#form_quote .subscript + ol {
  padding-top: 4px;
  margin-top: 0;
}
#form_quote h5:last-child,
#form_quote .h5:last-child,
#form_quote .subscript:last-child {
  margin-bottom: 4px;
}
#form_quote h5 + h1,
#form_quote h5 + h2,
#form_quote h5 + h3,
#form_quote h5 + h4,
#form_quote h5 + h5,
#form_quote h5 + h6,
#form_quote .h5 + h1,
#form_quote .h5 + h2,
#form_quote .h5 + h3,
#form_quote .h5 + h4,
#form_quote .h5 + h5,
#form_quote .h5 + h6,
#form_quote .subscript + h1,
#form_quote .subscript + h2,
#form_quote .subscript + h3,
#form_quote .subscript + h4,
#form_quote .subscript + h5,
#form_quote .subscript + h6 {
  padding-top: 4px;
}
#form_quote h4,
#form_quote .h4,
#form_quote .exsmall {
  font-size: 1em;
  line-height: 1.286em;
}
@media screen and (min-width: 768px) {
  #form_quote h4,
  #form_quote .h4,
  #form_quote .exsmall {
    font-size: 1.286em;
    line-height: 1em;
  }
}
#form_quote h4 + p,
#form_quote h4 + p.psub,
#form_quote h4 + p + p,
#form_quote h4 + p + p:empty + *,
#form_quote h4 + div,
#form_quote h4 + p:empty + *,
#form_quote h4 + ul,
#form_quote h4 + ol,
#form_quote .h4 + p,
#form_quote .h4 + p.psub,
#form_quote .h4 + p + p,
#form_quote .h4 + p + p:empty + *,
#form_quote .h4 + div,
#form_quote .h4 + p:empty + *,
#form_quote .h4 + ul,
#form_quote .h4 + ol,
#form_quote .exsmall + p,
#form_quote .exsmall + p.psub,
#form_quote .exsmall + p + p,
#form_quote .exsmall + p + p:empty + *,
#form_quote .exsmall + div,
#form_quote .exsmall + p:empty + *,
#form_quote .exsmall + ul,
#form_quote .exsmall + ol {
  padding-top: 8px;
  margin-top: 0;
}
#form_quote h4:last-child,
#form_quote .h4:last-child,
#form_quote .exsmall:last-child {
  margin-bottom: 8px;
}
#form_quote h4 + h1,
#form_quote h4 + h2,
#form_quote h4 + h3,
#form_quote h4 + h4,
#form_quote h4 + h5,
#form_quote h4 + h6,
#form_quote .h4 + h1,
#form_quote .h4 + h2,
#form_quote .h4 + h3,
#form_quote .h4 + h4,
#form_quote .h4 + h5,
#form_quote .h4 + h6,
#form_quote .exsmall + h1,
#form_quote .exsmall + h2,
#form_quote .exsmall + h3,
#form_quote .exsmall + h4,
#form_quote .exsmall + h5,
#form_quote .exsmall + h6 {
  padding-top: 8px;
}
#form_quote h3,
#form_quote .h3,
#form_quote .small {
  font-size: 1.283em;
  line-height: 1.111em;
}
@media screen and (min-width: 768px) {
  #form_quote h3,
  #form_quote .h3,
  #form_quote .small {
    font-size: 1.429em;
    line-height: 1em;
  }
}
#form_quote h3 + p,
#form_quote h3 + p.psub,
#form_quote h3 + p + p,
#form_quote h3 + p + p:empty + *,
#form_quote h3 + div,
#form_quote h3 + p:empty + *,
#form_quote h3 + ul,
#form_quote h3 + ol,
#form_quote .h3 + p,
#form_quote .h3 + p.psub,
#form_quote .h3 + p + p,
#form_quote .h3 + p + p:empty + *,
#form_quote .h3 + div,
#form_quote .h3 + p:empty + *,
#form_quote .h3 + ul,
#form_quote .h3 + ol,
#form_quote .small + p,
#form_quote .small + p.psub,
#form_quote .small + p + p,
#form_quote .small + p + p:empty + *,
#form_quote .small + div,
#form_quote .small + p:empty + *,
#form_quote .small + ul,
#form_quote .small + ol {
  padding-top: 8px;
  margin-top: 0;
}
#form_quote h3:last-child,
#form_quote .h3:last-child,
#form_quote .small:last-child {
  margin-bottom: 8px;
}
#form_quote h3 + h1,
#form_quote h3 + h2,
#form_quote h3 + h3,
#form_quote h3 + h4,
#form_quote h3 + h5,
#form_quote h3 + h6,
#form_quote .h3 + h1,
#form_quote .h3 + h2,
#form_quote .h3 + h3,
#form_quote .h3 + h4,
#form_quote .h3 + h5,
#form_quote .h3 + h6,
#form_quote .small + h1,
#form_quote .small + h2,
#form_quote .small + h3,
#form_quote .small + h4,
#form_quote .small + h5,
#form_quote .small + h6 {
  padding-top: 8px;
}
#form_quote h2,
#form_quote .h2,
#form_quote .med {
  font-size: 1.423em;
  line-height: 1.1em;
}
@media screen and (min-width: 768px) {
  #form_quote h2,
  #form_quote .h2,
  #form_quote .med {
    font-size: 2.143em;
    line-height: 1em;
  }
}
#form_quote h2 + p,
#form_quote h2 + p.psub,
#form_quote h2 + p + p,
#form_quote h2 + p + p:empty + *,
#form_quote h2 + div,
#form_quote h2 + p:empty + *,
#form_quote h2 + ul,
#form_quote h2 + ol,
#form_quote .h2 + p,
#form_quote .h2 + p.psub,
#form_quote .h2 + p + p,
#form_quote .h2 + p + p:empty + *,
#form_quote .h2 + div,
#form_quote .h2 + p:empty + *,
#form_quote .h2 + ul,
#form_quote .h2 + ol,
#form_quote .med + p,
#form_quote .med + p.psub,
#form_quote .med + p + p,
#form_quote .med + p + p:empty + *,
#form_quote .med + div,
#form_quote .med + p:empty + *,
#form_quote .med + ul,
#form_quote .med + ol {
  padding-top: 16px;
  margin-top: 0;
}
#form_quote h2:last-child,
#form_quote .h2:last-child,
#form_quote .med:last-child {
  margin-bottom: 16px;
}
#form_quote h2 + h1,
#form_quote h2 + h2,
#form_quote h2 + h3,
#form_quote h2 + h4,
#form_quote h2 + h5,
#form_quote h2 + h6,
#form_quote .h2 + h1,
#form_quote .h2 + h2,
#form_quote .h2 + h3,
#form_quote .h2 + h4,
#form_quote .h2 + h5,
#form_quote .h2 + h6,
#form_quote .med + h1,
#form_quote .med + h2,
#form_quote .med + h3,
#form_quote .med + h4,
#form_quote .med + h5,
#form_quote .med + h6 {
  padding-top: 16px;
}
#form_quote h1,
#form_quote .h1,
#form_quote .lrg {
  font-size: 2.143em;
  line-height: 1em;
}
@media screen and (min-width: 768px) {
  #form_quote h1,
  #form_quote .h1,
  #form_quote .lrg {
    font-size: 3.571em;
    line-height: 1em;
  }
}
#form_quote h1 + p,
#form_quote h1 + p.psub,
#form_quote h1 + p + p,
#form_quote h1 + p + p:empty + *,
#form_quote h1 + div,
#form_quote h1 + p:empty + *,
#form_quote h1 + ul,
#form_quote h1 + ol,
#form_quote .h1 + p,
#form_quote .h1 + p.psub,
#form_quote .h1 + p + p,
#form_quote .h1 + p + p:empty + *,
#form_quote .h1 + div,
#form_quote .h1 + p:empty + *,
#form_quote .h1 + ul,
#form_quote .h1 + ol,
#form_quote .lrg + p,
#form_quote .lrg + p.psub,
#form_quote .lrg + p + p,
#form_quote .lrg + p + p:empty + *,
#form_quote .lrg + div,
#form_quote .lrg + p:empty + *,
#form_quote .lrg + ul,
#form_quote .lrg + ol {
  padding-top: 16px;
  margin-top: 0;
}
#form_quote h1:last-child,
#form_quote .h1:last-child,
#form_quote .lrg:last-child {
  margin-bottom: 16px;
}
#form_quote h1 + h1,
#form_quote h1 + h2,
#form_quote h1 + h3,
#form_quote h1 + h4,
#form_quote h1 + h5,
#form_quote h1 + h6,
#form_quote .h1 + h1,
#form_quote .h1 + h2,
#form_quote .h1 + h3,
#form_quote .h1 + h4,
#form_quote .h1 + h5,
#form_quote .h1 + h6,
#form_quote .lrg + h1,
#form_quote .lrg + h2,
#form_quote .lrg + h3,
#form_quote .lrg + h4,
#form_quote .lrg + h5,
#form_quote .lrg + h6 {
  padding-top: 16px;
}
#form_quote h1[data-pre]:before {
  content: attr(data-pre);
}
#form_quote h1[data-pre]:before,
#form_quote h1.pre-header span {
  font-size: 0.67em;
  line-height: 1.3em;
  white-space: normal;
  display: block;
  max-width: 100%;
}
#form_quote h1[data-pre],
#form_quote h1.pre-header {
  display: grid;
  grid-template-columns: 1fr;
}
#form_quote .xlrg,
#form_quote .xl {
  font-size: 50px;
  line-height: 0.88em;
}
@media screen and (min-width: 768px) {
  #form_quote .xlrg,
  #form_quote .xl {
    font-size: 80px;
    line-height: 1em;
  }
}
#form_quote h1 + div.section,
#form_quote h2 + div.section,
#form_quote h3 + div.section,
#form_quote h4 + div.section,
#form_quote h5 + div.section {
  padding-top: 64px;
}
#form_quote h1 + p.p-sub + div.section,
#form_quote h2 + p.p-sub + div.section,
#form_quote h3 + p.p-sub + div.section,
#form_quote h4 + p.p-sub + div.section,
#form_quote h5 + p.p-sub + div.section {
  padding-top: 64px;
}
#form_quote .jumbo {
  font-size: 8vw;
  line-height: 0.8em;
}
#form_quote .block .section.header p {
  max-width: 688px;
  margin: auto;
}
#form_quote p {
  color: #1B1B1B;
}
#form_quote p.p-sub,
#form_quote p.oversize,
#form_quote li.p-sub,
#form_quote span.p-sub {
  margin-top: 0;
  font-size: 1.286em;
  line-height: 1.5em;
  margin-bottom: 0;
}
#form_quote p.p-sub.reduce,
#form_quote p.oversize.reduce,
#form_quote li.p-sub.reduce,
#form_quote span.p-sub.reduce {
  font-size: 1em;
}
@media screen and (min-width: 768px) {
  #form_quote p.p-sub.reduce,
  #form_quote p.oversize.reduce,
  #form_quote li.p-sub.reduce,
  #form_quote span.p-sub.reduce {
    font-size: 1.286em;
  }
}
#form_quote .p-sub a,
#form_quote .p-sub a.p-sub {
  font-weight: 600 !important;
}
#form_quote p.small {
  font-size: 0.857em;
}
#form_quote img + p:not(.p-sub):not(:empty),
#form_quote picture + p:not(.p-sub):not(:empty) {
  padding-top: 24px;
}
#form_quote p:not(.p-sub):not(:empty) + p,
#form_quote p:not(.p-sub):not(:empty) + ul,
#form_quote p:not(.p-sub):not(:empty) + ol {
  padding-top: 1.5rem;
}
#form_quote p:not(.p-sub):not(:empty) + img {
  padding-top: 24px;
}
#form_quote ul + p:not(.p-sub):not(:empty),
#form_quote ol + p:not(.p-sub):not(:empty) {
  padding-top: 1.5rem;
}
#form_quote p:not(.p-sub) + p.small {
  padding-top: 70px;
}
#form_quote p.oversize + p.oversize {
  padding-top: 45px;
}
#form_quote p.oversize + p:not(.oversize) {
  padding-top: 65px;
}
@font-face {
  font-family: "cc-glyphs";
  src: url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/cc-glyphs.eot?i9m337");
  src: url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/cc-glyphs.eot?i9m337#iefix") format("embedded-opentype"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/cc-glyphs.ttf?i9m337") format("truetype"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/cc-glyphs.woff?i9m337") format("woff"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/cc-glyphs.svg?i9m337#cc-glyphs") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
#form_quote [class^=ccg-],
#form_quote [class*=" ccg-"] {
  font-family: "cc-glyphs" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#form_quote .ccg-star:before {
  content: "\f005";
}
#form_quote .ccg-shopping-cart:before {
  content: "\f07a";
}
#form_quote .ccg-star-half:before {
  content: "\f089";
}
#form_quote .ccg-arrow-up-boxed:before {
  content: "\e900";
}
#form_quote .ccg-arrow-down-boxed:before {
  content: "\e901";
}
#form_quote .ccg-arrow-down:before {
  content: "\e902";
}
#form_quote .ccg-arrow-up:before {
  content: "\e903";
}
#form_quote .ccg-find-search:before {
  content: "\e904";
}
#form_quote .ccg-find-2-search-2:before {
  content: "\e905";
}
#form_quote .ccg-cancel-no-cross:before {
  content: "\e906";
}
#form_quote .ccg-check-checkmark:before {
  content: "\e907";
}
#form_quote .ccg-no-outline-boxed-cancel-outline-boxed-cross-outline-boxed:before {
  content: "\e908";
}
#form_quote .ccg-success-badge:before {
  content: "\e909";
}
#form_quote .ccg-error-badge:before {
  content: "\e90a";
}
#form_quote .ccg-edit-comment:before {
  content: "\e90b";
}
#form_quote .ccg-mail-message:before {
  content: "\e90c";
}
#form_quote .ccg-hourglass:before {
  content: "\e90d";
}
#form_quote .ccg-check-boxed-checkmark-boxed:before {
  content: "\e90e";
}
#form_quote .ccg-info-badge:before {
  content: "\e90f";
}
#form_quote .ccg-bolt-outline:before {
  content: "\e910";
}
#form_quote .ccg-bolt:before {
  content: "\e911";
}
#form_quote .ccg-attention-badge:before {
  content: "\e912";
}
#form_quote .ccg-info-badge1:before {
  content: "\e913";
}
#form_quote .ccg-jolly-roger:before {
  content: "\e914";
}
#form_quote .ccg-flag:before {
  content: "\e915";
}
#form_quote .ccg-flag-outline:before {
  content: "\e916";
}
#form_quote .ccg-thumbsup:before {
  content: "\e917";
}
#form_quote .ccg-whammo-outline:before {
  content: "\e918";
}
#form_quote .ccg-whammo:before {
  content: "\e919";
}
#form_quote .ccg-bookmark-outline:before {
  content: "\e91a";
}
#form_quote .ccg-bookmark:before {
  content: "\e91b";
}
#form_quote .ccg-expiring:before {
  content: "\e91c";
}
#form_quote .ccg-lock:before {
  content: "\e91d";
}
#form_quote .ccg-question:before {
  content: "\e91e";
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#form_quote .hidden {
  display: none;
}
#form_quote .fa,
#form_quote .fas,
#form_quote .far,
#form_quote .fal,
#form_quote .fad,
#form_quote .fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
#form_quote .fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
#form_quote .fa-xs {
  font-size: 0.75em;
}
#form_quote .fa-sm {
  font-size: 0.875em;
}
#form_quote .fa-1x {
  font-size: 1em;
}
#form_quote .fa-2x {
  font-size: 2em;
}
#form_quote .fa-3x {
  font-size: 3em;
}
#form_quote .fa-4x {
  font-size: 4em;
}
#form_quote .fa-5x {
  font-size: 5em;
}
#form_quote .fa-6x {
  font-size: 6em;
}
#form_quote .fa-7x {
  font-size: 7em;
}
#form_quote .fa-8x {
  font-size: 8em;
}
#form_quote .fa-9x {
  font-size: 9em;
}
#form_quote .fa-10x {
  font-size: 10em;
}
#form_quote .fa-fw {
  text-align: center;
  width: 1.25em;
}
#form_quote .fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
#form_quote .fa-ul > li {
  position: relative;
}
#form_quote .fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
#form_quote .fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}
#form_quote .fa-pull-left {
  float: left;
}
#form_quote .fa-pull-right {
  float: right;
}
#form_quote .fa.fa-pull-left,
#form_quote .fas.fa-pull-left,
#form_quote .far.fa-pull-left,
#form_quote .fal.fa-pull-left,
#form_quote .fab.fa-pull-left {
  margin-right: 0.3em;
}
#form_quote .fa.fa-pull-right,
#form_quote .fas.fa-pull-right,
#form_quote .far.fa-pull-right,
#form_quote .fal.fa-pull-right,
#form_quote .fab.fa-pull-right {
  margin-left: 0.3em;
}
#form_quote .fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
#form_quote .fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#form_quote .fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
#form_quote .fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
#form_quote .fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
#form_quote .fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
#form_quote .fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}
#form_quote .fa-flip-both,
#form_quote .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}
#form_quote :root .fa-rotate-90,
#form_quote :root .fa-rotate-180,
#form_quote :root .fa-rotate-270,
#form_quote :root .fa-flip-horizontal,
#form_quote :root .fa-flip-vertical,
#form_quote :root .fa-flip-both {
  -webkit-filter: none;
  filter: none;
}
#form_quote .fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}
#form_quote .fa-stack-1x,
#form_quote .fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
#form_quote .fa-stack-1x {
  line-height: inherit;
}
#form_quote .fa-stack-2x {
  font-size: 2em;
}
#form_quote .fa-inverse {
  color: #fff;
}
#form_quote .fa-facebook-f:before {
  content: "\f39e";
}
#form_quote .fa-instagram:before {
  content: "\f16d";
}
#form_quote .fa-star:before {
  content: "\f005";
}
#form_quote .fa-star-half:before {
  content: "\f089";
}
#form_quote .fa-twitter:before {
  content: "\f099";
}
#form_quote .fa-youtube:before {
  content: "\f167";
}
#form_quote .sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
#form_quote .sr-only-focusable:active,
#form_quote .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-brands-400.eot");
  src: url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-brands-400.woff2") format("woff2"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-brands-400.woff") format("woff"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-brands-400.ttf") format("truetype"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-brands-400.svg#fontawesome") format("svg");
}
#form_quote .fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-solid-900.eot");
  src: url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-solid-900.woff2") format("woff2"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-solid-900.woff") format("woff"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-solid-900.ttf") format("truetype"), url("https://www.boatsmartexam.com/wp-content/plugins/phoenix-common-elements/campfire-styles/fonts/fa-solid-900.svg#fontawesome") format("svg");
}
#form_quote .fa,
#form_quote .fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}
#form_quote .sg-outline {
  outline: 1px solid gray;
  display: inline-block;
}
#form_quote .gray-bg {
  background-color: #f6f6f6;
}
#form_quote .clay-bg {
  background-color: #DDDCD6;
}
#form_quote .black-bg {
  background-color: #000000;
}
#form_quote .slate-bg {
  background-color: #1B1B1B;
}
#form_quote .test:after {
  content: "This is nothing";
}
#form_quote code[class*=language-],
#form_quote pre[class*=language-] {
  margin: 0;
}
#form_quote * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#form_quote body {
  color: #1b1b1b;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5rem;
}
#form_quote img {
  max-width: 100%;
  height: auto;
  margin: auto;
}
#form_quote div {
  box-sizing: border-box;
}
#form_quote a:not(.button):not(.normalize),
#form_quote button.link:not(.plain) {
  color: #1B1B1B;
}
#form_quote a:not(.button):not(.normalize).invert,
#form_quote button.link:not(.plain).invert {
  color: #FFFFFF;
}
#form_quote button.link {
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
#form_quote .section.split.full img,
#form_quote .section.split.full picture {
  margin: initial;
}
#form_quote .block {
  text-align: center;
}
#form_quote .block > *:first-child:not(.square) {
  padding-top: 160px;
}
#form_quote .block > *:last-child:not(.square) {
  padding-bottom: 160px;
}
#form_quote .block > div.section {
  max-width: 960px;
  margin: auto;
  vertical-align: middle;
  justify-content: flex-start;
}
#form_quote .block > div.section:not(.full) {
  box-sizing: content-box;
}
@media screen and (min-width: 768px) {
  #form_quote .block > div.section:not(.full) {
    padding-left: 104px;
    padding-right: 104px;
  }
}
#form_quote .block > div.section.header + div.section {
  padding-top: 80px;
}
#form_quote .block > div.section.header > *:last-child {
  padding-bottom: 16px;
}
#form_quote .block > div.section:not(.square):not(.banner):only-of-type {
  min-height: 560px;
}
#form_quote .block > div.section + .section:not(.square) {
  padding-top: 96px;
}
#form_quote .block > div.section.full {
  max-width: 100%;
  justify-content: center;
}
#form_quote .block > div.section.full.split {
  text-align: left;
}
#form_quote .block > div.section:not(.split) {
  display: flex;
  flex-direction: column;
}
#form_quote .block > div.section:not(.split):only-child {
  box-sizing: content-box;
  padding-left: 104px;
  padding-right: 104px;
}
@media screen and (max-width: 959px) {
  #form_quote .block > div.section:not(.split):only-child {
    padding: 160px 5%;
  }
}
#form_quote .block > div.section:not(.split):not(.grid) > div,
#form_quote .block > div.section:not(.split) .content,
#form_quote .block > div.section:not(.split) + ul li {
  display: inline-block;
  width: 100%;
  margin: auto;
}
#form_quote .block > div.section.banner {
  margin: auto;
  min-height: 280px;
}
#form_quote .block > div.section.banner:only-child:not(.split) {
  padding: 80px 0;
}
#form_quote .block > div.section.banner.constrain {
  max-height: 388px;
}
#form_quote .block > div.section .content-block {
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  #form_quote .block > div.section > div:not(.anchor),
  #form_quote .block > div.section > li {
    margin: auto;
    padding: 5%;
  }
}
#form_quote .block > div.section.wide {
  max-width: 1280px;
}
@media screen and (min-width: 768px) {
  #form_quote .block > div.section.grid {
    box-sizing: content-box;
    padding-left: 7%;
    padding-right: 7%;
    margin: auto;
    vertical-align: middle;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    gap: 100px 14%;
  }
  #form_quote .block > div.section.grid > div {
    text-align: left;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  #form_quote .block > div.section.grid > div {
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  #form_quote .block > div.section.blog {
    gap: 40px 20px;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  #form_quote .block > div.section.blog {
    grid-template-columns: repeat(2, min-content);
  }
}
@media screen and (min-width: 768px) and (min-width: 1280px) {
  #form_quote .block > div.section.blog {
    grid-template-columns: repeat(4, min-content);
  }
}
#form_quote .block > div.section.split.full {
  align-content: center;
}
#form_quote .block > div.section.split.full div.cta {
  padding: 32px 64px 16px 0px;
}
#form_quote .block > div.section.split.full div.cta button,
#form_quote .block > div.section.split.full div.cta a.button {
  margin-left: 0;
}
#form_quote .block > div.section.split.full:not(.square) {
  max-width: 1440px;
}
@media screen and (min-width: 768px) {
  #form_quote .block > div.section.split.full:not(.square) > div {
    padding-left: 104px;
    padding-right: 104px;
  }
  #form_quote .block > div.section.split.full:not(.square).reverse > div:first-child {
    padding-left: 0;
  }
  #form_quote .block > div.section.split.full:not(.square).reverse > div:first-child > div {
    box-sizing: content-box;
    padding-left: 52px;
  }
  #form_quote .block > div.section.split.full:not(.square).reverse > div:last-child {
    padding-right: 0;
  }
  #form_quote .block > div.section.split.full:not(.square).reverse > div:last-child > div {
    box-sizing: content-box;
    padding-right: 52px;
  }
  #form_quote .block > div.section.split.full:not(.square):not(.reverse) > div:first-child {
    padding-right: 0;
  }
  #form_quote .block > div.section.split.full:not(.square):not(.reverse) > div:first-child > div {
    box-sizing: content-box;
    padding-right: 52px;
  }
  #form_quote .block > div.section.split.full:not(.square):not(.reverse) > div:last-child {
    padding-left: 0;
  }
  #form_quote .block > div.section.split.full:not(.square):not(.reverse) > div:last-child > div {
    box-sizing: content-box;
    padding-left: 52px;
  }
}
@media screen and (min-width: 768px) {
  #form_quote .block > div.section.split {
    display: flex;
    align-content: center;
  }
  #form_quote .block > div.section.split.early {
    display: initial;
  }
  #form_quote .block > div.section.split.early > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding: 5%;
  }
  #form_quote .block > div.section.split.early > div > div {
    padding-right: 0 !important;
  }
  #form_quote .block > div.section.split.full > div > div {
    max-width: 75%;
  }
  #form_quote .block > div.section.split:only-child > div:not(.anchor),
  #form_quote .block > div.section.split:only-child > li:not(.anchor) {
    margin: 0;
  }
  #form_quote .block > div.section.split:not(.thin):not(.grid) > div,
  #form_quote .block > div.section.split:only-child:not(.thin):not(.grid) > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: inherit;
    position: relative;
    z-index: 1;
  }
  #form_quote .block > div.section.split:not(.thin):not(.grid) > div.anchor img:only-of-type,
  #form_quote .block > div.section.split:not(.thin):not(.grid) > div.anchor picture:only-of-type,
  #form_quote .block > div.section.split:only-child:not(.thin):not(.grid) > li.anchor img:only-of-type,
  #form_quote .block > div.section.split:only-child:not(.thin):not(.grid) > li.anchor picture:only-of-type {
    object-fit: cover;
    min-width: 100%;
    height: 100%;
  }
  #form_quote .block > div.section.split:not(.thin):not(.grid) > div.anchor > div,
  #form_quote .block > div.section.split:only-child:not(.thin):not(.grid) > li.anchor > div {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  #form_quote .block > div.section.split > .gap > div:last-of-type {
    margin-left: 15px;
  }
  #form_quote .block > div.section.split.even > div,
  #form_quote .block > div.section.split .even > li {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #form_quote .block > div.section.split.square > div.anchor,
  #form_quote .block > div.section.split.square li {
    overflow: hidden;
  }
  #form_quote .block > div.section.split.square > div.anchor img,
  #form_quote .block > div.section.split.square li img {
    position: absolute;
    height: 100%;
    width: auto;
  }
  #form_quote .block > div.section.split.normalize > div {
    padding: 0;
  }
  #form_quote .block > div.section.split.reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 1024px) {
  #form_quote .block > div.section.split.early {
    display: flex;
  }
  #form_quote .block > div.section.split.constrain > div,
  #form_quote .block > div.section.split.constrain > picture img,
  #form_quote .block > div.section.split.constrain > img {
    max-height: 600px;
  }
  #form_quote .block > div.section.split.constrain-thin > div {
    max-height: 388px;
  }
  #form_quote .block > div.section.split.full > div > div {
    max-width: 475px;
  }
  #form_quote .block > div.section.split.square.all > div {
    height: 50vw;
    overflow: hidden;
  }
  #form_quote .block > div.section.split.square > div.anchor {
    height: 50vw;
    overflow: hidden;
    justify-content: start;
  }
  #form_quote .block > div.section.split.square > div.anchor img {
    position: absolute;
  }
}
#form_quote div.cta {
  width: 100%;
  padding: 32px 32px 16px;
}
@media screen and (min-width: 768px) {
  #form_quote div.cta {
    padding: 32px 64px 16px;
  }
}
#form_quote div.cta.callout.alt {
  display: flex;
  flex-wrap: wrap;
}
#form_quote div.cta.callout.alt button {
  margin: 0 16px 16px 0;
}
@media screen and (max-width: 767px) {
  #form_quote div.cta.callout.alt {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
  #form_quote div.cta.callout.alt button {
    margin: 0 auto 16px;
  }
}
#form_quote div.cta.multibutton {
  padding-bottom: 32px;
  display: inline-grid;
  justify-content: left;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 180px));
  gap: 32px 32px;
}
#form_quote div.cta.multibutton.alt {
  padding: 0;
  display: flex;
  justify-content: left;
  margin: auto;
  flex-wrap: wrap;
}
#form_quote div.cta.multibutton.alt.grow button {
  flex-grow: 1;
}
#form_quote div.cta.multibutton.alt button,
#form_quote div.cta.multibutton.alt a.button {
  margin: 16px 16px;
}
#form_quote div.cta.multibutton.alt button::after,
#form_quote div.cta.multibutton.alt a.button::after {
  width: 16px;
  margin-right: 16px;
}
#form_quote div.cta.multibutton.alt button::before,
#form_quote div.cta.multibutton.alt a.button::before {
  width: 16px;
  margin-left: 16px;
}
#form_quote .badge {
  position: relative;
  display: inline-block;
}
#form_quote .badge:after {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  content: attr(data-badge-content);
  min-width: 22px;
  min-height: 22px;
  line-height: 22px;
  border-radius: 100%;
  background-color: #1B1B1B;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}
#form_quote .badge.large:after {
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
  font-size: 20px;
}
#form_quote .block > h1:first-child + .section.split > div,
#form_quote .block > h2:first-child + .section.split > div,
#form_quote .block > h3:first-child + .section.split > div,
#form_quote .block > h4:first-child + .section.split > div,
#form_quote .block > h5:first-child + .section.split > div,
#form_quote .block > .p-sub:first-child + .section.split > div {
  padding-top: 0;
}
#form_quote ul {
  list-style: none;
}
#form_quote .block > div.section ol > li {
  display: list-item;
}
#form_quote ol {
  padding-left: 1em;
}
#form_quote ul {
  text-align: left;
  padding: 0;
}
#form_quote picture {
  display: inline-block;
}
#form_quote p.p-sub.boatsmart {
  color: #2c5424;
}
#form_quote p.p-sub.huntingsmart {
  color: #d2451e;
}
#form_quote p.p-sub.paddlersmart {
  color: #3E728C;
}
#form_quote p.p-sub.sledsmart {
  color: #243746;
}
#form_quote p.p-sub.atvsmart {
  color: #483829;
}
#form_quote a.plain.strip-color {
  overflow: hidden;
}
#form_quote a.plain.strip-color > div {
  transition: all 0.1s linear;
}
#form_quote a.plain.strip-color > div:hover {
  filter: grayscale(0.6);
  transform: scale(1.05);
}
#form_quote a.button:disabled,
#form_quote button:disabled {
  background-color: #a7a9ac !important;
}
#form_quote a.button.outline,
#form_quote button.outline {
  position: relative;
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
  border: 2px solid #1B1B1B;
  border-radius: 2px;
  overflow: hidden;
  z-index: 2;
}
#form_quote a.button.outline::after,
#form_quote button.outline::after {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #1B1B1B;
  content: " ";
  position: absolute;
  top: 0;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translateY(48px);
  transform: translateY(48px);
  z-index: -1;
}
#form_quote a.button.outline:hover,
#form_quote a.button.outline.active,
#form_quote button.outline:hover,
#form_quote button.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.outline:hover::after,
#form_quote a.button.outline.active::after,
#form_quote button.outline:hover::after,
#form_quote button.outline.active::after {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
#form_quote a.button.inverted-outline,
#form_quote button.inverted-outline {
  background: none;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
}
#form_quote a.button.inverted-outline:hover,
#form_quote button.inverted-outline:hover {
  background: #FFFFFF;
  color: #1b1b1b;
}
#form_quote a.button.boatsmart:not(.outline):not(.link),
#form_quote button.boatsmart:not(.outline):not(.link) {
  background-color: #2c5424;
  color: #FFFFFF;
}
#form_quote a.button.boatsmart:not(.outline):not(.link):hover,
#form_quote button.boatsmart:not(.outline):not(.link):hover {
  background-color: #3f7833;
}
#form_quote a.button.boatsmart.outline,
#form_quote button.boatsmart.outline {
  border: 2px solid #2c5424;
  color: #2c5424;
}
#form_quote a.button.boatsmart.outline::after,
#form_quote button.boatsmart.outline::after {
  background-color: #2c5424;
  color: #FFFFFF;
}
#form_quote a.button.boatsmart.outline:hover,
#form_quote a.button.boatsmart.outline.active,
#form_quote button.boatsmart.outline:hover,
#form_quote button.boatsmart.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.huntingsmart:not(.outline):not(.link),
#form_quote button.huntingsmart:not(.outline):not(.link) {
  background-color: #d2451e;
  color: #FFFFFF;
}
#form_quote a.button.huntingsmart:not(.outline):not(.link):hover,
#form_quote button.huntingsmart:not(.outline):not(.link):hover {
  background-color: #a53618;
}
#form_quote a.button.huntingsmart.outline,
#form_quote button.huntingsmart.outline {
  border: 2px solid #d2451e;
  color: #d2451e;
}
#form_quote a.button.huntingsmart.outline::after,
#form_quote button.huntingsmart.outline::after {
  background-color: #d2451e;
  color: #FFFFFF;
}
#form_quote a.button.huntingsmart.outline:hover,
#form_quote a.button.huntingsmart.outline.active,
#form_quote button.huntingsmart.outline:hover,
#form_quote button.huntingsmart.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.paddlersmart:not(.outline):not(.link),
#form_quote button.paddlersmart:not(.outline):not(.link) {
  background-color: #3E728C;
  color: #FFFFFF;
}
#form_quote a.button.paddlersmart:not(.outline):not(.link):hover,
#form_quote button.paddlersmart:not(.outline):not(.link):hover {
  background-color: #243746;
}
#form_quote a.button.paddlersmart.outline,
#form_quote button.paddlersmart.outline {
  border: 2px solid #3E728C;
  color: #3E728C;
}
#form_quote a.button.paddlersmart.outline::after,
#form_quote button.paddlersmart.outline::after {
  background-color: #3E728C;
  color: #FFFFFF;
}
#form_quote a.button.paddlersmart.outline:hover,
#form_quote a.button.paddlersmart.outline.active,
#form_quote button.paddlersmart.outline:hover,
#form_quote button.paddlersmart.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.sledsmart:not(.outline):not(.link),
#form_quote button.sledsmart:not(.outline):not(.link) {
  background-color: #243746;
  color: #FFFFFF;
}
#form_quote a.button.sledsmart:not(.outline):not(.link):hover,
#form_quote button.sledsmart:not(.outline):not(.link):hover {
  background-color: #3E728C;
}
#form_quote a.button.sledsmart.outline,
#form_quote button.sledsmart.outline {
  border: 2px solid #243746;
  color: #243746;
}
#form_quote a.button.sledsmart.outline::after,
#form_quote button.sledsmart.outline::after {
  background-color: #243746;
  color: #FFFFFF;
}
#form_quote a.button.sledsmart.outline:hover,
#form_quote a.button.sledsmart.outline.active,
#form_quote button.sledsmart.outline:hover,
#form_quote button.sledsmart.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.atvsmart:not(.outline):not(.link),
#form_quote button.atvsmart:not(.outline):not(.link) {
  background-color: #483829;
  color: #FFFFFF;
}
#form_quote a.button.atvsmart:not(.outline):not(.link):hover,
#form_quote button.atvsmart:not(.outline):not(.link):hover {
  background-color: #68513c;
}
#form_quote a.button.atvsmart.outline,
#form_quote button.atvsmart.outline {
  border: 2px solid #483829;
  color: #483829;
}
#form_quote a.button.atvsmart.outline::after,
#form_quote button.atvsmart.outline::after {
  background-color: #483829;
  color: #FFFFFF;
}
#form_quote a.button.atvsmart.outline:hover,
#form_quote a.button.atvsmart.outline.active,
#form_quote button.atvsmart.outline:hover,
#form_quote button.atvsmart.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.cc-midnight:not(.outline):not(.link),
#form_quote button.cc-midnight:not(.outline):not(.link) {
  background-color: #1b1b1b;
  color: #FFFFFF;
}
#form_quote a.button.cc-midnight:not(.outline):not(.link):hover,
#form_quote button.cc-midnight:not(.outline):not(.link):hover {
  background-color: #000;
}
#form_quote a.button.cc-midnight.outline,
#form_quote button.cc-midnight.outline {
  border: 2px solid #1b1b1b;
  color: #1b1b1b;
}
#form_quote a.button.cc-midnight.outline::after,
#form_quote button.cc-midnight.outline::after {
  background-color: #1b1b1b;
  color: #FFFFFF;
}
#form_quote a.button.cc-midnight.outline:hover,
#form_quote a.button.cc-midnight.outline.active,
#form_quote button.cc-midnight.outline:hover,
#form_quote button.cc-midnight.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.cc-water:not(.outline):not(.link),
#form_quote button.cc-water:not(.outline):not(.link) {
  background-color: #243746;
  color: #FFFFFF;
}
#form_quote a.button.cc-water:not(.outline):not(.link):hover,
#form_quote button.cc-water:not(.outline):not(.link):hover {
  background-color: #355168;
}
#form_quote a.button.cc-water.outline,
#form_quote button.cc-water.outline {
  border: 2px solid #243746;
  color: #243746;
}
#form_quote a.button.cc-water.outline::after,
#form_quote button.cc-water.outline::after {
  background-color: #243746;
  color: #FFFFFF;
}
#form_quote a.button.cc-water.outline:hover,
#form_quote a.button.cc-water.outline.active,
#form_quote button.cc-water.outline:hover,
#form_quote button.cc-water.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.forest:not(.outline):not(.link),
#form_quote button.forest:not(.outline):not(.link) {
  background-color: #2c5424;
  color: #FFFFFF;
}
#form_quote a.button.forest:not(.outline):not(.link):hover,
#form_quote button.forest:not(.outline):not(.link):hover {
  background-color: #3f7833;
}
#form_quote a.button.forest.outline,
#form_quote button.forest.outline {
  border: 2px solid #2c5424;
  color: #2c5424;
}
#form_quote a.button.forest.outline::after,
#form_quote button.forest.outline::after {
  background-color: #2c5424;
  color: #FFFFFF;
}
#form_quote a.button.forest.outline:hover,
#form_quote a.button.forest.outline.active,
#form_quote button.forest.outline:hover,
#form_quote button.forest.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.ice:not(.outline):not(.link),
#form_quote button.ice:not(.outline):not(.link) {
  background-color: #ABC0D1;
  color: #FFFFFF;
}
#form_quote a.button.ice:not(.outline):not(.link):hover,
#form_quote button.ice:not(.outline):not(.link):hover {
  background-color: #8aa7bf;
}
#form_quote a.button.ice.outline,
#form_quote button.ice.outline {
  border: 2px solid #ABC0D1;
  color: #ABC0D1;
}
#form_quote a.button.ice.outline::after,
#form_quote button.ice.outline::after {
  background-color: #ABC0D1;
  color: #243746;
}
#form_quote a.button.ice.outline:hover,
#form_quote a.button.ice.outline.active,
#form_quote button.ice.outline:hover,
#form_quote button.ice.outline.active {
  color: #243746;
}
#form_quote a.button.grass:not(.outline):not(.link),
#form_quote button.grass:not(.outline):not(.link) {
  background-color: #495a46;
  color: #FFFFFF;
}
#form_quote a.button.grass:not(.outline):not(.link):hover,
#form_quote button.grass:not(.outline):not(.link):hover {
  background-color: #323D30;
}
#form_quote a.button.grass.outline,
#form_quote button.grass.outline {
  border: 2px solid #495a46;
  color: #495a46;
}
#form_quote a.button.grass.outline::after,
#form_quote button.grass.outline::after {
  background-color: #495a46;
  color: #FFFFFF;
}
#form_quote a.button.grass.outline:hover,
#form_quote a.button.grass.outline.active,
#form_quote button.grass.outline:hover,
#form_quote button.grass.outline.active {
  color: #FFFFFF;
}
#form_quote a.button.cc-moss:not(.outline):not(.link),
#form_quote button.cc-moss:not(.outline):not(.link) {
  background-color: #70794C;
  color: #FFFFFF;
}
#form_quote a.button.cc-moss:not(.outline):not(.link):hover,
#form_quote button.cc-moss:not(.outline):not(.link):hover {
  background-color: #535a38;
}
#form_quote a.button.cc-moss.outline,
#form_quote button.cc-moss.outline {
  border: 2px solid #70794C;
  color: #70794C;
}
#form_quote a.button.cc-moss.outline::after,
#form_quote button.cc-moss.outline::after {
  background-color: #70794C;
  color: #FFFFFF;
}
#form_quote a.button.cc-moss.outline:hover,
#form_quote a.button.cc-moss.outline.active,
#form_quote button.cc-moss.outline:hover,
#form_quote button.cc-moss.outline.active {
  color: #FFFFFF;
}
@media screen and (min-width: 768px) {
  #form_quote #block-hero .hero-image-container .hero-image {
    transform: translateX(calc(25px - 20%));
  }
}
#form_quote #block-hero h1 + h2 {
  margin-top: 15px;
}
#form_quote #block-hero h1 + p {
  max-width: 680px;
}
#form_quote #block-hero.lost-card-hero h1 + p {
  margin: auto;
}
#form_quote #block-hero div.cta.multibutton .pricing-block {
  text-align: center;
}
#form_quote #block-hero div.cta.multibutton .pricing-block b {
  display: block;
}
#form_quote #block-hero div.cta.multibutton {
  padding-left: 0;
  padding-right: 0;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px 16px;
}
#form_quote #block-hero div.cta.multibutton button {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  padding: 15px 0;
}
#form_quote #block-hero > div.section {
  box-sizing: content-box;
  padding: 100px 10%;
  max-height: 768px;
  max-width: 1280px;
}
@media screen and (min-width: 768px) {
  #form_quote #block-hero > div.section {
    padding: 100px 105px;
  }
}
#form_quote #block-hero .hero-content {
  text-align: left;
  padding: 60px 15px 60px 0px;
}
#form_quote #block-hero .hero-image-container .hero-image {
  display: none;
}
#form_quote #block-hero .qty-select {
  margin-left: 8px;
}
#form_quote #block-hero .cta.four_button .four-button-list {
  display: inline-flex;
}
#form_quote #block-hero .cta.four_button .qty-select {
  display: none;
}
#form_quote #block-hero .mobile-hero-image {
  margin: 30px auto;
}
#form_quote #block-hero .cta.four_button {
  margin: 32px 0;
}
@media screen and (min-width: 426px) {
  #form_quote #block-hero .mobile-hero-image {
    display: none;
  }
  #form_quote #block-hero .hero-image-container {
    display: flex;
  }
  #form_quote #block-hero .hero-image-container .hero-image {
    display: block;
  }
}
@media screen and (max-width: 636px) {
  #form_quote #block-hero div.cta:not(.multibutton) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  #form_quote #block-hero div.cta:not(.multibutton) a.button {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  #form_quote #block-hero .hero-content {
    text-align: center;
    padding: 0;
  }
  #form_quote #block-hero .hero-image-container .hero-image {
    transform: none;
  }
}
@media screen and (max-width: 1152px) {
  #form_quote #block-hero .cta.four_button .four-button-list {
    display: none;
  }
  #form_quote #block-hero .cta.four_button .qty-select {
    display: block;
  }
}
#form_quote .owl-next,
#form_quote .owl-prev {
  background-color: #FFFFFF;
}
#form_quote .fa-star,
#form_quote .fa-star-half {
  color: #daac61;
  padding: 0px 3px;
}
#form_quote #overallStars {
  padding: 36px 0px;
}
#form_quote #overallStars i {
  font-size: 20px;
  padding: 0px 5.5px;
}
#form_quote .reviewCopy {
  overflow: hidden;
  height: 110px;
  font-style: italic;
  padding: 12px 36px 0px;
  font-family: "Lato", sans-serif;
}
#form_quote .review--header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
}
#form_quote .reviewer {
  font-size: 14px;
  font-weight: 600;
}
#form_quote .timeAgo,
#form_quote .posted {
  font-size: 12px;
}
#form_quote .review--brand_container {
  padding: 8px 20px;
  font-weight: 600;
  text-align: center;
}
#form_quote .review--container {
  padding-bottom: 24px;
  overflow: hidden;
}
#form_quote .owl-stage-outer {
  display: flex;
  flex-direction: row;
}
#form_quote .owl-stage {
  display: flex;
}
#form_quote .review--item {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 33%;
  padding: 0px 30px;
}
#form_quote button.owl-prev {
  position: absolute;
  top: 5%;
  left: -5%;
  border: none;
  font-size: 26px;
}
#form_quote button.owl-next {
  position: absolute;
  top: 5%;
  right: -5%;
  border: none;
  font-size: 26px;
}
#form_quote .review--section {
  display: flex;
}
#form_quote .review--footer {
  display: flex;
  text-align: center;
  flex-direction: column;
  padding-top: 12px;
}
#form_quote .review--footer a {
  font-weight: 600;
  color: black;
  margin: 24px 0px;
}
#form_quote .review--footer img {
  display: flex;
  margin: 0 auto;
}
@media (max-width: 992px) {
  #form_quote .review--section {
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
  #form_quote .review--item {
    padding: 12px 0px;
  }
  #form_quote .rio--prev {
    display: none;
  }
  #form_quote .rio--next {
    display: none;
  }
  #form_quote .reviewCopy {
    overflow: hidden;
    height: 150px;
    padding: 12px 18px 0px;
  }
}
@media (min-width: 992px) {
  #form_quote .reviewCopy {
    width: 280px;
  }
}
@media (max-width: 600px) {
  #form_quote .reviewCopy {
    height: 100px;
    padding: 12px 18px 0px;
  }
  #form_quote .owl-next,
  #form_quote .owl-prev {
    top: 40%;
  }
}
@media screen and (min-width: 426px) {
  #form_quote *.mobile-only {
    display: none !important;
  }
}
@media screen and (max-width: 426px) {
  #form_quote *.mobile-up {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  #form_quote *.tablet-up {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  #form_quote *.tablet-down {
    display: none !important;
  }
}
@media screen and (max-width: 1023px) {
  #form_quote *.desktop-up {
    display: none !important;
  }
}
@media screen and (min-width: 1023px) {
  #form_quote *.desktop-down {
    display: none;
  }
}
#form_quote img,
#form_quote picture {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
#form_quote .embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
#form_quote .embed-container iframe,
#form_quote .embed-container object,
#form_quote .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#form_quote p:empty {
  display: none !important;
}
#form_quote .relative {
  position: relative;
}
#form_quote .section.pull-up,
#form_quote .block.pull-up,
#form_quote .pull-up {
  margin-top: -160px;
}
#form_quote .block > div:not(.split) p.narrow {
  box-sizing: content-box;
  max-width: 683px;
  margin: auto;
}
#form_quote p.narrow {
  max-width: 688px;
}
#form_quote div.narrow {
  padding-left: 15%;
  padding-right: 15%;
}
#form_quote .anchor.anchor-right img {
  object-position: right;
}
#form_quote .anchor.anchor-left img {
  object-position: left;
}
#form_quote .stuck {
  position: fixed !important;
  top: 0;
}
#form_quote picture.alignleft img,
#form_quote img.alignleft {
  margin-left: 0;
}
#form_quote picture.alignright img,
#form_quote img.alignright {
  margin-right: 0;
}
#form_quote #content {
  width: 100%;
  overflow-x: hidden;
}
#form_quote form .input-group {
  display: grid;
  padding-bottom: 24px;
  justify-content: center;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(230px, max-content));
  grid-gap: 8px;
}
#form_quote form .input-group .input-wrapper {
  position: relative;
}
#form_quote form .input-group .input-wrapper label:not(.checkbox-container) {
  padding-left: 2px;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 11px;
  font-size: 12px;
  transform: translateY(50%);
  transition: all 100ms ease-in-out;
  pointer-events: none;
}
#form_quote form .input-group .input-wrapper.active label,
#form_quote form .input-group .input-wrapper.select label {
  font-size: 10px;
  transform: none;
}
#form_quote form .input-group .input-wrapper.select::before {
  content: "X";
  display: block;
  border-left: 2px solid #1B1B1B;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: calc(100% - 20px);
  min-width: 50px;
  pointer-events: none;
}
#form_quote form .input-group .input-wrapper.active input {
  padding-top: 8px;
}
#form_quote form .input-group .select-wrapper select + label {
  opacity: 0;
}
#form_quote form .input-group .select-wrapper.active select + label {
  opacity: 1;
}
#form_quote form .input-group .select-wrapper.active select {
  transition: padding-top 100ms ease-in-out;
  padding-top: 10px;
}
#form_quote input:not([type=range]),
#form_quote select:not(#lang_choice_1) {
  background-color: #FFFFFF;
  -webkit-appearance: none;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  border: 1px solid #1b1b1b;
  height: 50px;
  min-width: 230px;
  border-radius: 2px;
  text-indent: 10px;
  position: relative;
}
#form_quote input:not([type=range]):focus:not(:disabled),
#form_quote select:not(#lang_choice_1):focus:not(:disabled) {
  border-width: 2px;
}
#form_quote input:not([type=range]):disabled,
#form_quote select:not(#lang_choice_1):disabled {
  border-width: 1px;
  border-color: #A8A9AC;
  background-color: #F6F6F6;
}
#form_quote input:not([type=range]):invalid:not(:focus),
#form_quote select:not(#lang_choice_1):invalid:not(:focus) {
  border-width: 2px;
  border-color: #BA0020;
  background-color: rgba(186, 0, 32, 0.0509803922);
}
#form_quote input[type=date] {
  padding-right: 12px;
  text-indent: 5px;
}
#form_quote .select-chevron {
  right: 16px;
  top: 20px !important;
  pointer-events: none;
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.6 21.1'%3E%3Cpath fill='%231b1b1b' d='M30.6 6.2L25.1.4l-9.9 9.4L5.8 0 0 5.5l15 15.6 2.3-2.2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
#form_quote select:disabled + .select-chevron {
  opacity: 0.3;
}
#form_quote #lang_choice_1 {
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  color: #FFFFFF;
  border: none;
  text-transform: uppercase;
  line-height: normal;
  border-radius: 0;
}
#form_quote li.p-sub a {
  line-height: 34px;
}
#form_quote ul.check {
  padding-left: 13px;
}
#form_quote ul.check li {
  padding-left: 1em;
}
#form_quote ul.check li:before {
  transform: translateY(12px);
  margin-left: -1.05em;
  display: inline-block;
  font-size: 40px;
  font-family: "cc-glyphs";
  content: "\e907";
  line-height: 0;
}
#form_quote ul li.check:before {
  content: "\e907";
}
#form_quote .check.large:before,
#form_quote .cross.large:before {
  font-size: 52px;
}
#form_quote ul.cross {
  padding-left: 13px;
}
#form_quote ul.cross li {
  padding-left: 1em;
}
#form_quote ul.cross li:before {
  transform: translateY(12px);
  margin-left: -1.05em;
  display: inline-block;
  font-size: 40px;
  font-family: "cc-glyphs";
  content: "\e906";
  line-height: 0;
}
#form_quote ul li.cross:before {
  content: "\e906";
}
#form_quote .checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#form_quote .checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#form_quote .checkbox-container .checkmark {
  box-sizing: border-box;
  border-radius: 2px;
  position: absolute;
  border: 1px solid #1b1b1b;
  top: 0;
  left: 0;
  height: 20px;
  width: 23px;
}
#form_quote .checkbox-container:hover input ~ .checkmark {
  border-width: 2px;
}
#form_quote .checkbox-container .checkmark:after {
  content: "";
  display: none;
}
#form_quote .checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
#form_quote .checkbox-container .checkmark:after {
  margin: auto;
  margin-top: 2px;
  width: 5px;
  height: 10px;
  border: solid #1b1b1b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#form_quote .number-picker {
  display: inline-flex;
}
#form_quote .number-picker > button:not(.link),
#form_quote .number-picker > input:not([type=range]) {
  width: 40px;
  height: 40px;
  text-indent: unset;
  display: inline-block;
  border: 1px solid #888888;
  border-radius: 0;
  padding: 7px;
  margin: 0;
  min-width: unset;
  text-decoration: none;
  background: #FFFFFF;
  color: #888888;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}
#form_quote .number-picker > *:first-child {
  border-right: none !important;
}
#form_quote .number-picker > *:last-child {
  border-left: none !important;
}
#form_quote body.hs #lifestyle-hero.stripe::after {
  mix-blend-mode: overlay;
  opacity: 1;
}
#form_quote #lifestyle-hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f6f6f6;
}
#form_quote #lifestyle-hero.ca.stripe::after {
  mix-blend-mode: multiply;
}
#form_quote #lifestyle-hero a.button.outline span {
  z-index: 2;
}
#form_quote #lifestyle-hero a.button.outline::after {
  z-index: 1;
}
#form_quote #lifestyle-hero .callout {
  display: none;
}
@media screen and (min-width: 768px) {
  #form_quote #lifestyle-hero .callout {
    display: inline-flex;
  }
}
#form_quote #lifestyle-hero .section {
  padding-bottom: 80px;
  max-width: 1280px;
  margin: auto;
  padding-top: 0;
}
@media screen and (min-width: 768px) {
  #form_quote #lifestyle-hero .section {
    padding-top: 160px;
  }
}
@media screen and (min-width: 960px) {
  #form_quote #lifestyle-hero .section {
    padding-left: 104px;
    padding-right: 104px;
  }
}
#form_quote #lifestyle-hero .content {
  background-color: #FFFFFF;
  margin-top: 85px;
  margin-right: auto;
  margin-left: 0;
  margin-bottom: 0;
  padding: 40px 5% 5%;
}
@media screen and (min-width: 678px) {
  #form_quote #lifestyle-hero .content {
    margin-top: auto;
    width: auto;
    padding: 42px;
    text-align: left;
  }
}
#form_quote #lifestyle-hero .content h1 {
  margin-top: 16px;
}
#form_quote #lifestyle-hero .content h1.pre-header span {
  font-size: 18px;
  line-height: 18px;
}
#form_quote #lifestyle-hero .content p {
  max-width: 667px;
}
#form_quote #lifestyle-hero .content .card-logo {
  display: inline-flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
#form_quote #lifestyle-hero .content .card-logo .state-card {
  padding-top: 16px;
}
#form_quote #lifestyle-hero .content .card-logo picture,
#form_quote #lifestyle-hero .content .card-logo img {
  display: block;
}
#form_quote #lifestyle-hero .content .card-logo .provincial-logo {
  max-width: 72px;
  max-height: 72px;
}
@media screen and (min-width: 768px) {
  #form_quote #lifestyle-hero .content .card-logo .provincial-logo {
    margin-left: 24px;
  }
}
@media screen and (min-width: 768px) {
  #form_quote #lifestyle-hero .content .card-logo {
    align-items: flex-end;
    flex-direction: row;
  }
  #form_quote #lifestyle-hero .content .card-logo .state-card {
    height: 75px;
    padding-top: 0;
  }
}
@media screen and (min-width: 678px) {
  #form_quote #lifestyle-hero img {
    margin: initial;
  }
}
#form_quote #lifestyle-hero div.cta:not(.multibutton) button {
  margin-right: 0;
}
@media screen and (min-width: 581px) {
  #form_quote #lifestyle-hero div.cta:not(.multibutton) button {
    margin-right: 16px;
  }
}
@media screen and (max-width: 425px) {
  #form_quote #lifestyle-hero div.cta:not(.multibutton) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
#form_quote #lifestyle-hero div.cta.multibutton {
  padding-bottom: 13px;
  padding-left: 0;
  padding-right: 0;
  display: inline-grid;
  justify-content: left;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, 157px);
  gap: 13px;
}
#form_quote #lifestyle-hero div.cta.multibutton .purchase-block {
  text-align: center;
  font-size: 12px;
  line-height: 14px;
}
#form_quote #lifestyle-hero div.cta.multibutton .purchase-block .pricing-block {
  padding-top: 16px;
}
#form_quote #lifestyle-hero div.cta.multibutton .purchase-block .price {
  opacity: 0;
  transition: opacity 0.1s ease-in;
}
#form_quote #lifestyle-hero div.cta.multibutton .purchase-block:hover .price {
  opacity: 1;
}
#form_quote #lifestyle-hero div.cta.multibutton button {
  width: 157px;
}
#form_quote #lifestyle-hero #regulator-info {
  height: 62px;
  max-height: 62px;
  padding: 0;
  position: absolute;
  right: 62px;
  bottom: 62px;
  display: none;
  font-size: 10px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: right;
}
#form_quote #lifestyle-hero #regulator-info > div {
  padding-right: 16px;
}
#form_quote #lifestyle-hero #regulator-info img {
  height: 62px;
}
@media screen and (min-width: 1024px) {
  #form_quote #lifestyle-hero #regulator-info {
    display: block;
  }
}
#form_quote a.button {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
#form_quote a.button,
#form_quote button:not(.link) {
  font-family: "DIN Next LT Pro", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  outline: none;
  height: 45px;
  min-height: 45px;
  min-width: 120px;
  font-size: 16px;
  padding: 9px 35px 5px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0);
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
}
#form_quote a.button:hover,
#form_quote button:not(.link):hover {
  background-color: rgba(0, 0, 0, 0);
}
@media screen and (min-width: 769px) {
  #form_quote a.button,
  #form_quote button:not(.link) {
    font-size: 18px;
    padding: 9px 35px 5px;
  }
}
#form_quote a.button.white,
#form_quote button:not(.link).white {
  font-family: "DIN Next LT Pro", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  outline: none;
  height: 45px;
  min-width: 120px;
  font-size: 16px;
  padding: 9px 35px 5px;
  border-radius: 2px;
  background-color: #fff;
  color: #1b1b1b;
  white-space: nowrap;
  cursor: pointer;
}
#form_quote a.button.white:hover,
#form_quote button:not(.link).white:hover {
  background-color: #e6e6e6;
}
@media screen and (min-width: 769px) {
  #form_quote a.button.white,
  #form_quote button:not(.link).white {
    font-size: 18px;
    padding: 9px 35px 5px;
  }
}
#form_quote a.plain,
#form_quote button.plain {
  color: inherit;
  transition: none;
  text-transform: inherit;
  text-decoration: none;
}
#form_quote a.plain :hover,
#form_quote button.plain :hover {
  color: inherit;
  transition: none;
}
#form_quote .bs-price {
  color: #FFFFFF;
}
#form_quote .bs-price.hs {
  color: #1B1B1B;
}
